import { LogLevels } from '@mate-academy/logger';
import { User } from '@/controllers/graphql/generated';
import { errorHandler } from '@/core/ErrorHandler';
import { getCookieValueByKey } from '@/controllers/analytics/analytics.utils/getCookieValueByKey';
import { logger } from '@/core/Logger';
import {
  FB_CLICK_ID_COOKIE_NAME,
  FB_FORMATTED_PIXEL_ID_COOKIE_NAME,
  FB_FORMATTED_CLICK_ID_COOKIE_NAME,
} from '@/controllers/analytics/analytics.constants';

type FBAnalyticsParams = Pick<User, 'fbp' | 'fbc' | 'fbclid'>;
export function getFBAnalyticsParams(): FBAnalyticsParams {
  const fbAnalyticsParams: FBAnalyticsParams = {
    fbp: '',
    fbc: '',
    fbclid: '',
  };

  try {
    fbAnalyticsParams.fbp = getCookieValueByKey(
      FB_FORMATTED_PIXEL_ID_COOKIE_NAME,
    );
    fbAnalyticsParams.fbc = getCookieValueByKey(
      FB_FORMATTED_CLICK_ID_COOKIE_NAME,
    );

    // // quick fix https://app.clickup.com/t/24383048/SUB-4348
    let fbclid = getCookieValueByKey(FB_CLICK_ID_COOKIE_NAME);

    // cover cases when fbclid contains URL
    if (fbclid?.includes('https://')) {
      const queryParamsString = fbclid.split('?')[1];
      const queryParams = new URLSearchParams(queryParamsString);

      fbclid = queryParams.get('fbclid') ?? undefined;
    }

    fbAnalyticsParams.fbclid = fbclid;

    if (!fbAnalyticsParams.fbc && fbAnalyticsParams.fbclid) {
      fbAnalyticsParams.fbc = `fb.1.${Date.now().valueOf()}.${fbAnalyticsParams.fbclid}`;
    }

    // cover case when fbc contains URL
    if (fbAnalyticsParams.fbc?.includes('https://')) {
      const queryParamsString = fbAnalyticsParams.fbc.split('?')[1];
      const fbcTimestamp = fbAnalyticsParams.fbc?.split('.')[2] ?? Date.now().valueOf();
      const queryParams = new URLSearchParams(queryParamsString);

      fbclid = queryParams.get('fbclid') ?? undefined;
      fbAnalyticsParams.fbclid = fbclid;

      fbAnalyticsParams.fbc = `fb.1.${fbcTimestamp}.${fbAnalyticsParams.fbclid}`;
    }
  } catch (error) {
    errorHandler.captureException(error as Error, {
      logMessage: 'Error occurred while attempting to get the Facebook analytics parameters',
      logLevel: LogLevels.Error,
      logger: logger.child('getFBAnalyticsParams'),
    });
  }

  return fbAnalyticsParams;
}
