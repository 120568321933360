import React, {
  FC, useState,
} from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { FormField } from '@/components/ui/FormElements/FormField';
import { InputCheckbox } from '@/components/ui/FormElements/FormInputs/InputCheckbox';
import { typography } from '@/components/ui/typography';
import { useConsentPrefilledState } from '@/controllers/auth/auth.hooks.ts/useConsentPrefilledState';
import { cn } from '@/lib';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';

interface Props {
  ssr?: boolean;
  isEnabled?: boolean;
  className?: string;
}

export const SignUpNewsletterSubscriptionCheckbox: FC<Props> = (props) => {
  const {
    isEnabled,
    className,
  } = props;

  const newslettersPrefilledState = useConsentPrefilledState({});
  const { brandName } = useSubDomainContext();
  const { submitting } = useFormState();

  const [isChecked, setIsChecked] = useState(newslettersPrefilledState);
  const fieldName = 'newslettersSubscription';
  const { t } = useTranslation([I18N_CODES.loginPage]);

  const handleChange = () => {
    setIsChecked((value) => !value);
  };

  return (
    <FormField
      renderInput={(inputProps) => (
        <InputCheckbox
          label={t(`${I18N_CODES.loginPage}:sign_up_marketing_checkbox_with_brand`, {
            brandName,
          })}
          {...inputProps}
          checked={isChecked}
          data-qa="user-newsletters-subscription"
          className={cn(typography.platformTextSmall, className)}
          config={{
            defaultValue: newslettersPrefilledState,
          }}
        />
      )}
      onChange={handleChange}
      disabled={submitting && !isEnabled}
      name={fieldName}
    />
  );
};
